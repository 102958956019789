import React, { useEffect } from 'react';
import { BrowserRouter, Route, Routes, useLocation } from 'react-router-dom';
import Header from "./components/header/header"
import Footer from "./components/footer/footer"
import Whatsappbtn from "./components/whatsappbtn/whatsappbtn"
import Downloadappbtn from "./components/chatbtn/downloadappbtn";
import Chatbtn from "./components/chatbtn/chatbtn";
import './App.css';
import './custom.css'
import './responsive.css'
import HomePage from './app/home';
import TermsAndConditions from "./components/policies/termsandconditions";
import PrivacyPolicy from "./components/policies/privacypolicy";
import RegistrationForm from "./components/onboarding-form/onboarding-form";
import PartnerForm from "./components/partner-form/partner-form";
import AppRedirect from "./components/deeplink/deeplink";
// import DownloadApp from "./components/deeplink/downloadapp"


function App() {
  useEffect(() => {
    const handleContextMenu = (e) => {
      e.preventDefault();
    };
    if (window.location.hostname !== 'localhost') {
      document.addEventListener('contextmenu', handleContextMenu);
    }
    return () => {
      document.removeEventListener('contextmenu', handleContextMenu);
    };
  }, []);
  const ScrollToTop = () => {
    const { pathname } = useLocation();

    useEffect(() => {
      window.scrollTo(0, 0);
    }, [pathname]);
    return null;
  };

  return (
    <div className="association-container">
      <BrowserRouter >
        <Header />
        <ScrollToTop />

        <Routes>
          <Route path="/" >
            <Route index element={<HomePage />} />
            <Route path="/terms" element={<TermsAndConditions />} />
            <Route path="/privacypolicy" element={<PrivacyPolicy />} />
            <Route path="/register" element={<RegistrationForm />} />
            <Route path="/partner" element={<PartnerForm />} />
            <Route path="/deeplink" element={<AppRedirect />} />
            {/* <Route path="/app" element={<DownloadApp />} /> */}
          </Route>
        </Routes>
        <Footer />
        <Whatsappbtn />
      <Downloadappbtn/>
      </BrowserRouter>

    </div>
  );
}

export default App;

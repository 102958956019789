import React from "react";
import "./chatbtn.css";


const Downloadappbtn = () => (
  <div className="chatbtn-container">
    <a
      id="chat-btn-triger"
      className="chatbtn"
      href="/deeplink"
    >
      <svg width="78" height="78" viewBox="0 0 78 78" fill="none" xmlns="http://www.w3.org/2000/svg">
        <circle cx="38.53" cy="38.53" r="38.53" fill="#F1675E" />
        <path d="M48.8357 34.5882H45.4286V25.7647C45.4286 24.7941 44.4643 24 43.2857 24H34.7143C33.5357 24 32.5714 24.7941 32.5714 25.7647V34.5882H29.1643C27.2571 34.5882 26.2929 36.4941 27.6429 37.6059L37.4786 45.7059C38.3143 46.3941 39.6643 46.3941 40.5 45.7059L50.3357 37.6059C51.6857 36.4941 50.7429 34.5882 48.8357 34.5882ZM24 52.2353C24 53.2059 24.9643 54 26.1429 54H51.8571C53.0357 54 54 53.2059 54 52.2353C54 51.2647 53.0357 50.4706 51.8571 50.4706H26.1429C24.9643 50.4706 24 51.2647 24 52.2353Z" fill="white" />
      </svg>


    </a>


  </div>
);

export default Downloadappbtn;
